import React, { Component } from "react";
import { Button } from "react-bootstrap";
import menuNames from "../menuNames.json";

class SeccionContacto extends Component {

  hideIf = condition => {
    if (condition) {
      return { display: "none" };
    } else {
      return {};
    }
  };

  handleClick(e){
    var trgt = e.target.id;
    var name = document.getElementById("personal_name").value;
    var business = document.getElementById("compercial_name").value;
    var info = document.getElementById("info").value;
    var phone = document.getElementById("phone").value;
    var cellphone = document.getElementById("cell_phone").value;

    var link;

    var txt = "¡Hola! Te contacto atravez de tu tarjeta " + menuNames.home.URLtarjeta + " te mando mi información de contacto:\n"
    txt += "Nombre personal: " + name + "\n";
    txt += "Nombre comercial: " + business + "\n";
    txt += "Información: " + info + "\n";
    txt += "Télefono fijo: " + phone + "\n";
    txt += "Télefono móvil: " + cellphone + "\n";
    txt += "Espero sabar de usted pronto.";
    switch (trgt) {
      case "whatsapp":
        link = "https://api.whatsapp.com/send?phone=" + menuNames.home.whatsapp + "&text=" + txt;
        break;
      case "sms":
        link = "sms:" + menuNames.home.sms + "&body=" + txt;
        break;
      case "email":
        link = "mailto:" + menuNames.home.email + "?body=" + txt;
        break;
      default:

    }
    window.open(link);
  }

  render() {
    return (
      <div>
        <h1 className="seccion">{menuNames.menu5}</h1>
        <div className="nombreLogoContacto">
          <div className="contacto">
            <div className="form-row">
              <div className="form-name form-col">NOMBRE PERSONAL</div>
              <div className="form-col">
                <input id="personal_name" type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-name form-col">NOMBRE COMERCIAL</div>
              <div className="form-col">
                <input id="compercial_name" type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-name form-col">INFORMACIÓN</div>
              <div className="form-col">
                <textarea id="info" type="text" rows="5"/>
              </div>
            </div>
            <div className="form-row">
              <div className="form-name form-col">TELÉFONO FIJO</div>
              <div className="form-col">
                <input id="phone" type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-name form-col">TELÉFONO MÓVIL</div>
              <div className="form-col">
                <input id="cell_phone" type="text" />
              </div>
            </div>
            <div className="form-row">
              <div style={this.hideIf(menuNames.home.whatsapp === "")} className="form-col">
                <Button id="whatsapp" className="secContacto_btn" onClick={this.handleClick} size="lg">
                  whatsapp
                </Button>
              </div>
              <div style={this.hideIf(menuNames.home.sms === "")} className="form-col">
                <Button id="sms" className="secContacto_btn" onClick={this.handleClick} size="lg">
                  sms
                </Button>
              </div>
              <div style={this.hideIf(menuNames.home.email === "")} className="form-col">
                <Button id="email" className="secContacto_btn" onClick={this.handleClick} size="lg">
                  email
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SeccionContacto;
